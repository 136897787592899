import fhirpath from 'fhirpath';

type DraftMedicationRequest = Omit<fhir4.MedicationRequest, 'subject'>;

const ManipuladoCoding: fhir4.Coding = {
  display: 'Compounded preparation',
  code: '1208954007',
  system: 'http://snomed.info/sct',
};

export const ManipuladoMedicationRequest: DraftMedicationRequest = {
  resourceType: 'MedicationRequest',
  intent: 'order',
  status: 'draft',
  category: [
    {
      coding: [ManipuladoCoding],
      text: 'Medicamento de preparação extemporânea (manipulado)',
    },
  ],
};

export function isManipulado(resouce: fhir4.FhirResource): boolean {
  if (resouce.resourceType === 'MedicationRequest') {
    return fhirpath.evaluate(
      resouce,
      `MedicationRequest.category.where(coding.code='${ManipuladoCoding.code}' and coding.system='${ManipuladoCoding.system}').exists()`,
    )[0] as boolean;
  }

  return false;
}
