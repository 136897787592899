/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ArtifactFormConfig } from '../components/ArtifactForm/ArtifactForm.types';
import { toInternalCodeableConceptValue } from '../components/ArtifactForm/ArtifactForm.business';

const PrescricaoMedicaFormConfig: ArtifactFormConfig = [
  {
    name: 'medicationCodeableConcept',
    element: 'medicationCodeableConcept',
    label: 'Medicamento',
    type: 'CodeableConcept',
    path: 'medicationCodeableConcept.select(text | coding.display).first()',
    toInternalValue: (resource, value) =>
      toInternalCodeableConceptValue(
        resource,
        'medicationCodeableConcept',
        value,
      ),
  },
  {
    name: 'dosageInstruction',
    element: 'dosageInstruction[0].text',
    label: 'Posologia',
    type: 'string',
    path: 'dosageInstruction.text',
    toInternalValue: (resource, value) => {
      const medicationRequest = resource as fhir4.MedicationRequest;

      if (medicationRequest.dosageInstruction) {
        const { dosageInstruction, ...request } = medicationRequest;
        const [dosage = {}] = dosageInstruction;

        return {
          ...request,
          dosageInstruction: [
            {
              ...dosage,
              text: value,
            },
          ],
        };
      }

      return {
        ...medicationRequest,
        dosageInstruction: [{ text: value }],
      };
    },
  },
  {
    name: 'additionalInstruction',
    element: 'dosageInstruction[0].additionalInstruction',
    label: 'Instruções adicionais',
    type: 'string',
    path: 'dosageInstruction.additionalInstruction.text',
    toInternalValue: (resource, value) => {
      const medicationRequest = resource as fhir4.MedicationRequest;

      if (medicationRequest.dosageInstruction) {
        const { dosageInstruction, ...request } = medicationRequest;
        const [dosage = {}] = dosageInstruction;

        return {
          ...request,
          dosageInstruction: [
            {
              ...dosage,
              additionalInstruction: [{ text: value }],
            },
          ],
        };
      }

      return {
        ...medicationRequest,
        dosageInstruction: [{ additionalInstruction: [{ text: value }] }],
      };
    },
  },
  {
    name: 'patientInstruction',
    element: 'dosageInstruction[0].patientInstruction',
    label: 'Instruções ao paciente',
    type: 'string',
    path: 'dosageInstruction.patientInstruction',
    toInternalValue: (resource, value) => {
      const medicationRequest = resource as fhir4.MedicationRequest;

      if (medicationRequest.dosageInstruction) {
        const { dosageInstruction, ...request } = medicationRequest;
        const [dosage = {}] = dosageInstruction;

        return {
          ...request,
          dosageInstruction: [
            {
              ...dosage,
              patientInstruction: value,
            },
          ],
        };
      }

      return {
        ...medicationRequest,
        dosageInstruction: [{ patientInstruction: value }],
      };
    },
  },
];

export default PrescricaoMedicaFormConfig;
