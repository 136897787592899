import React from 'react';

import ArtifactForm, {
  ArtifactFormComponentProps,
} from '@/features/artifacts/components/ArtifactForm';
import { ArtifactFieldInfo } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';
import { useArtifactFormConfig } from '@/features/artifacts/hooks/useArtifactFormConfig';

const ProcedimentoFormDefault: ArtifactFieldInfo[] = [
  { name: 'reasonCode', label: 'Indicação clínica', required: true },
  { name: 'bodySite', label: 'Local do Corpo' },
  { name: 'orderDetail', label: 'Observações' },
];

export type FormProcedimentoProps = ArtifactFormComponentProps;

const FormProcedimento: React.FC<FormProcedimentoProps> = ({
  resource,
  onSubmit,
  onCancel,
  disabled = false,
  loading = false,
  readOnly = false,
}) => {
  const form = useArtifactFormConfig('procedimento', ProcedimentoFormDefault);

  return (
    <ArtifactForm
      form={form}
      resource={resource}
      loading={loading}
      disabled={disabled}
      readOnly={readOnly}
      onResourceSubmit={onSubmit}
      onResourceSubmitCancel={onCancel}
    />
  );
};

export default FormProcedimento;
