import { FiAlertCircle, FiEdit2 } from 'react-icons/fi';
import styled, { css } from 'styled-components';

import colors from '@/design/colors';
import { HeadingSmall, Text } from '@/design/typography';

import DetailsCollapseButton from '@/components/DetailsCollapseButton';
import IconButton from '@/components/IconButton';
import TextareaAutoResizable from '@/components/TextareaAutoResizable';

export const Container = styled.div<{ detailsOpen?: boolean }>`
  min-width: 0;
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.gray100};
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

  ${props =>
    props.detailsOpen &&
    css`
      max-height: 100rem;
      transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
    `};
`;

export const Header = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  min-height: 32px;
`;

export const HeaderTitle = styled.h3`
  flex: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  transition: color 0.3s ease;
  color: ${colors.gray300};
  padding-right: 161px; // Action buttons width + 16px spacing
`;

export const HeaderSubTitle = styled.h3<{ highlight?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 0 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  align-self: left;
  color: ${colors.gray300};

  ${props =>
    !props.highlight &&
    css`
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;

      word-break: keep-all;
      overflow: hidden;
    `};
`;
export const EditableContent = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;

  row-gap: 16px;

  > div {
    &:first-child {
      margin-right: 16px;
    }

    flex: 1;
    min-width: 48%;
    max-width: 100%;

    :not(:last-child) {
      > div {
        max-height: 38px;
      }
    }
  }
`;

export const TextAreaInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextAreaInputTitle = styled.label`
  ${HeadingSmall(14)};
  color: ${colors.blackAlpha200};
  margin-bottom: 8px;
`;

export const TextAreaInputField = styled(TextareaAutoResizable)`
  flex: 1;
  ${Text(14)};
  resize: none;
  overflow: hidden;
  padding: 9px 8px;
  min-height: 38px;

  border-radius: 8px;

  border: 1px solid ${colors.gray100};
  background: ${colors.white};
  color: ${colors.blackAlpha200};

  :focus {
    border: 1px solid ${colors.purple100};
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const CollpsableContent = styled.div<{ highlight?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 8px 0 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  justify-content: right;
  color: ${colors.gray100};

  ${props =>
    !props.highlight &&
    css`
      display: none;
    `};
`;

export const Actions = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
  min-height: 32px;
`;

export const EditTools = styled.div`
  position: absolute;
  top: 0;
  right: 50px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  gap: 16px;
  min-height: 32px;
`;

export const EditedLabel = styled.span`
  ${Text(14)};
  color: ${colors.gray200};
`;

export const EditButton = styled(IconButton).attrs({
  icon: FiEdit2,
  tooltip: 'Editar procedimento',
})`
  /* position: absolute;
  top: 0;
  right: 50px; */
`;

export const ExpandButton = styled(DetailsCollapseButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: flex-end;

  margin-top: 16px;
`;

export const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    + button {
      margin-left: 16px;
    }
  }
`;

export const ButtonContainer = styled.button`
  width: 16px;
  height: 16px;

  border-radius: 16px;

  cursor: default;
`;

export const InfoContainerIcon = styled(FiAlertCircle).attrs({
  color: colors.blackAlpha200,
})`
  width: 16px;
  height: 16px;
  margin-right: 16px;
`;
