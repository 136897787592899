import React from 'react';

import { SearchRef } from '@/components/Search';

import { TipoItemComplementar } from '../../models';
import { useAllowedMenu } from '../../helpers/permissions';
import CondutaComplementarService from '../../services/CondutaComplementarService';
import { useCondutaComplementarContext } from '../../providers/CondutaComplementarProvider';

import ArtefatoSearch from '../ArtefatoSearch';
import { SearchResultItem } from '../SearchResultBox';
import AddManipuladosButton from '../AddManipuladosButton';

import { Container, SearchResults } from './styles';

const ItemComplementarSearch: React.FC = () => {
  const options = useAllowedMenu();
  const {
    state: { generalType, isMenuOpen },
    setGeneralType,
    addItem,
  } = useCondutaComplementarContext();

  const [loading, setLoading] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState<
    SearchResultItem[] | undefined
  >();

  const searchRef = React.useRef<SearchRef>(null);

  const handleSearch = React.useCallback(
    async (type: TipoItemComplementar, term: string) => {
      if (term) {
        try {
          setLoading(true);
          const results = await CondutaComplementarService.search({
            term,
            type,
          });
          setSearchResults(results);
          // eslint-disable-next-line no-empty
        } catch {
        } finally {
          setLoading(false);
        }
      } else {
        setSearchResults(undefined);
      }
    },
    [],
  );

  const handleSelectSearchResult = React.useCallback(
    (result: SearchResultItem) => {
      // Clear search
      setSearchResults(undefined);

      if (result.data) {
        // Items que são adicionados e exigem a confirmação antes de adicioná-los ao bundle
        const shouldConfirmFirst = [
          'encaminhamento',
          'prescricao-medica',
        ].includes(generalType);

        addItem({
          type: generalType,
          resource: result.data,
          itemState: {
            // Medicamentos e encaminhamentos já são adicionados em edição
            isEditing: shouldConfirmFirst,
            isAdding: shouldConfirmFirst,
          },
        });
      }

      if (searchRef.current) {
        searchRef.current.clear();
      }
    },
    [addItem, generalType],
  );

  const clearSearch = React.useCallback(() => {
    setSearchResults(undefined);

    if (searchRef.current) {
      searchRef.current.clear();
    }
  }, []);

  const handleTipoSelect = React.useCallback(
    (tipo: TipoItemComplementar) => {
      setGeneralType(tipo);
      clearSearch();
    },
    [clearSearch, setGeneralType],
  );

  const SearchResultsAction = React.useMemo(() => {
    if (generalType === 'prescricao-medica') {
      return <AddManipuladosButton onAddManipulado={() => clearSearch()} />;
    }

    return <></>;
  }, [clearSearch, generalType]);

  React.useEffect(() => {
    // Clear search input and results states after toggle side menu
    clearSearch();
  }, [clearSearch, isMenuOpen]);

  return (
    <Container>
      <ArtefatoSearch
        tipoMenu={generalType}
        onTipoSelect={handleTipoSelect}
        onSearchArtefato={handleSearch}
        options={options}
        ref={searchRef}
      />

      <SearchResults
        results={searchResults}
        loading={loading}
        onResultItemClick={handleSelectSearchResult}
        ResultsFooter={SearchResultsAction}
      />
    </Container>
  );
};

export default ItemComplementarSearch;
