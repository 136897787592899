import React from 'react';

import FeedbackGraphic from '@/components/FeedbackGraphic';

import { useCondutaComplementarContext } from '../../providers/CondutaComplementarProvider';

import CondutaSideBox from '../CondutaSideBox';
import ItemComplementarSearch from '../ItemComplementarSearch';
import PrescricoesLivreEncaminhamento from '../PrescricoesLivreEncaminhamento';
import PrescricoesLivreExame from '../PrescricoesLivreExame';
import PrescricoesLivreMedicamento from '../PrescricoesLivreMedicamento';
import PrescricoesLivreProcedimento from '../PrescricoesLivreProcedimento';

const CondutaMenu: React.FC = () => {
  const { items, mode } = useCondutaComplementarContext();

  const ListEmpty = React.useMemo(() => {
    if (mode === 'read-only') {
      return (
        <FeedbackGraphic
          graphic="empty-box"
          title="Sem itens complementares"
          subtitle="Não foram adicionados itens complementares a este protocolo"
        />
      );
    }

    return (
      <FeedbackGraphic
        graphic="empty-box"
        title="Ainda sem itens complementares"
        subtitle="Os itens adicionados serão prescritos no seu portuário"
      />
    );
  }, [mode]);

  const GroupList = React.useMemo(() => {
    return (
      <>
        <PrescricoesLivreExame />
        <PrescricoesLivreProcedimento />
        <PrescricoesLivreEncaminhamento />
        <PrescricoesLivreMedicamento />
      </>
    );
  }, []);

  const Header = React.useMemo(() => {
    if (mode === 'active') {
      return <ItemComplementarSearch />;
    }

    return <></>;
  }, [mode]);

  return (
    <CondutaSideBox
      title="Prescrição de itens complementares"
      HeaderComponent={Header}
    >
      {items.length ? GroupList : ListEmpty}
    </CondutaSideBox>
  );
};

export default CondutaMenu;
