import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { RenderableElement } from '@/utils/elements';

import {
  BoxList,
  LoadingItem,
  EmptyItem,
  ResultList,
  ResultListItem,
  Fade,
} from './styles';

export interface SearchResultItem {
  value: string;
  label: string;
  data?: fhir4.FhirResource;
}

interface Props {
  results?: SearchResultItem[];
  onResultItemClick?: (resultItem: SearchResultItem) => void;
  loading?: boolean;
  className?: string;
  style?: React.CSSProperties;
  ResultsFooter?: RenderableElement;
}

const SearchResultBox: React.FC<Props> = ({
  results,
  onResultItemClick,
  loading = false,
  className,
  style,
  ResultsFooter,
}) => {
  const handleItemClick = React.useCallback(
    (item: SearchResultItem) => {
      if (onResultItemClick) {
        onResultItemClick(item);
      }
    },
    [onResultItemClick],
  );

  const showResults = React.useMemo(() => loading || results !== undefined, [
    loading,
    results,
  ]);

  return (
    <CSSTransition
      in={showResults}
      classNames="fade"
      timeout={150}
      unmountOnExit
    >
      <Fade className={className} style={style}>
        <BoxList
          data={results || []}
          ListContentContainerElement={ResultList}
          keyExtractor={({ item }) => item.value}
          renderItem={({ item }) => (
            <ResultListItem>
              <button type="button" onClick={() => handleItemClick(item)}>
                {item.label}
              </button>
            </ResultListItem>
          )}
          ListHeaderComponent={() =>
            loading ? <LoadingItem>Buscando...</LoadingItem> : <></>
          }
          ListEmptyComponent={() =>
            !loading ? (
              <EmptyItem>Nenhum resultado encontrado</EmptyItem>
            ) : (
              <></>
            )
          }
          ListFooterComponent={ResultsFooter}
        />
      </Fade>
    </CSSTransition>
  );
};

export default SearchResultBox;
