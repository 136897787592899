import React from 'react';

import { ArtifactType } from '@/features/artifacts/models/ArtifactForm';
import {
  ArtifactFieldInfo,
  ArtifactFormConfig,
  ArtifactFormFields,
} from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';
import { useAuth } from '@/features/auth/providers/AuthProvider/hooks';

import {
  buildForm,
  getForm,
} from '../components/ArtifactForm/ArtifactForm.helpers';

export function useArtifactFormConfig(
  artifactType: ArtifactType,
  defaultFields: ArtifactFormFields,
): ArtifactFormConfig {
  const { user } = useAuth();

  const modelForm = React.useMemo(() => getForm(artifactType), [artifactType]);

  const config = React.useMemo(() => {
    let fields = defaultFields;

    if (user && user.empresa) {
      const form = user.empresa.forms.find(
        ({ artifact_type }) => artifact_type === artifactType,
      );

      if (form) {
        fields = form.fields.map<ArtifactFieldInfo>(
          ({ fieldname: name, label, readonly, required }) => ({
            name,
            label,
            readonly,
            required,
          }),
        );
      }
    }

    return buildForm(modelForm, fields);
  }, [artifactType, defaultFields, modelForm, user]);

  return config;
}
