import styled from 'styled-components';

import colors from '@/design/colors';
import ButtonExtra from '@/components/ButtonExtra';

import SearchResultBox from '../SearchResultBox';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const SearchResults = styled(SearchResultBox)`
  position: absolute;
  top: 52px;

  width: 100%;

  z-index: 1;
`;

export const ButtonSearchResultsAction = styled(ButtonExtra)`
  height: 38px;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-image: repeating-linear-gradient(
      90deg,
      ${colors.purple100},
      ${colors.purple100} 15px,
      transparent 15px,
      transparent 30px,
      ${colors.purple100} 30px
    );
    background-size: 100% 1px;
    background-position: 0 0;
  }
`;
