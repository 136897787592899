import React from 'react';

import Feature from '@/features/features/components/Feature';
import AddManipuladosButtonUI from '../AddManipuladosButtonUI';

import { useCondutaComplementarContext } from '../../providers/CondutaComplementarProvider';
import { ManipuladoMedicationRequest } from '../../helpers/manipulados';
import { AddingItemComplementarState } from '../../models';

interface Props {
  onAddManipulado?: (item: AddingItemComplementarState) => void;
}

const AddManipuladosButton: React.FC<Props> = ({ onAddManipulado }) => {
  const { addItem } = useCondutaComplementarContext();

  const addManipulado = React.useCallback(() => {
    const item: AddingItemComplementarState = {
      resource: ManipuladoMedicationRequest as fhir4.MedicationRequest,
      type: 'prescricao-medica',
      itemState: {
        isAdding: true,
        isEditing: true,
      },
    };

    addItem(item);

    if (onAddManipulado) {
      onAddManipulado(item);
    }
  }, [addItem, onAddManipulado]);

  return (
    <Feature name="prescricaolivre.medicamentos.manipulados">
      <AddManipuladosButtonUI onClick={addManipulado} />
    </Feature>
  );
};

export default AddManipuladosButton;
