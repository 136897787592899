/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  ArtifactFormConfig,
  FormLoadingState,
  ResourceSubmitHandler,
} from './ArtifactForm.types';
import ArtifactBaseForm from '../ArtifactBaseForm';
import ArtifactFormProvider, {
  ArtifactFormConsumer,
} from '../../providers/ArtifactFormProvider';

type RType = fhir4.FhirResource;

interface Props {
  form: ArtifactFormConfig;
  resource: RType;
  disabled?: boolean;
  loading?: FormLoadingState;
  readOnly?: boolean;
  onResourceSubmit?: ResourceSubmitHandler;
  onResourceSubmitCancel?: ResourceSubmitHandler;
}

export interface ArtifactFormComponentProps {
  resource: RType;
  disabled?: boolean;
  loading?: FormLoadingState;
  readOnly?: boolean;
  onSubmit: ResourceSubmitHandler;
  onCancel: ResourceSubmitHandler;
}

function ArtifactForm({
  form,
  resource,
  loading = false,
  disabled = false,
  readOnly = false,
  onResourceSubmit,
  onResourceSubmitCancel,
}: Props): React.ReactElement<Props> {
  const onCancel = React.useCallback(() => {
    if (onResourceSubmitCancel) {
      onResourceSubmitCancel(resource);
    }
  }, [onResourceSubmitCancel, resource]);

  return (
    <ArtifactFormProvider form={form} resource={resource}>
      <ArtifactFormConsumer>
        {({ handleResourceSubmit }) => (
          <ArtifactBaseForm
            form={form}
            onCancel={onCancel}
            onSubmit={
              onResourceSubmit && handleResourceSubmit(onResourceSubmit)
            }
            loading={loading}
            disabled={disabled}
            readOnly={readOnly}
          />
        )}
      </ArtifactFormConsumer>
    </ArtifactFormProvider>
  );
}

export default ArtifactForm;
