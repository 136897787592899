import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import ArtifactFieldControl from './ArtifactFieldControl';
import { ArtifactFormConfig } from './ArtifactForm.types';
import { ArtifactFieldLayout, Fieldset } from './styles';

interface Props {
  form: ArtifactFormConfig;
  control: UseFormReturn['control'];
  style?: React.CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
}

const ArtifactFieldset: React.FC<Props> = ({
  form,
  control,
  loading = false,
  disabled = false,
  readOnly = true,
  style,
  className,
}) => {
  return (
    <Fieldset style={style} className={className}>
      {form.map(field => {
        return (
          <ArtifactFieldLayout type={field.type} key={field.name}>
            <ArtifactFieldControl
              control={control}
              config={{
                ...field,
                readonly: readOnly,
                // autoFocus: index === 0,
              }}
              disabled={loading || disabled}
            />
          </ArtifactFieldLayout>
        );
      })}
    </Fieldset>
  );
};

export default ArtifactFieldset;
