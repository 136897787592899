/* eslint-disable react/jsx-props-no-spreading */
import fhirpath from 'fhirpath';
import React from 'react';

import { FhirPedidoType } from '@/features/enterprise/models/types';
import IntegracaoService from '@/features/enterprise/services/IntegracaoService';

import FormProcedimento from '../FormProcedimento';
import {
  Actions,
  CollpsableContent,
  Container,
  EditButton,
  EditedLabel,
  EditTools,
  ExpandButton,
  Header,
  HeaderSubTitle,
  HeaderTitle,
} from './styles';

interface BoxProcedimentoEditavelProps {
  procedimento: FhirPedidoType;
  detailsOpened?: boolean;
  disabled: boolean;
}

const BoxProcedimentoEditavel: React.FC<BoxProcedimentoEditavelProps> = ({
  procedimento,
  detailsOpened,
  disabled,
}) => {
  const subtitleRef = React.useRef<HTMLHeadingElement>(null);
  const editableContentRef = React.useRef<HTMLDivElement>(null);

  const [isOpenForEdit, setIsOpenForEdit] = React.useState(
    detailsOpened || false,
  );
  const [isSubtitleExpanded, setIsSubtitleExpanded] = React.useState(false);
  const [
    procedimentoEditavel,
    setProcedimentoEditavel,
  ] = React.useState<FhirPedidoType>(procedimento);
  const [isSubtitleExpandable, setSubtitleExpandable] = React.useState(false);
  const [loadingConfirmation, setloadingConfirmation] = React.useState(false);

  const nome = React.useMemo(() => {
    return fhirpath.evaluate(
      procedimentoEditavel,
      'ServiceRequest.code.text | ServiceRequest.code.coding.display',
    )[0];
  }, [procedimentoEditavel]);

  const comentario = React.useMemo(() => {
    const note = fhirpath.evaluate(
      procedimentoEditavel,
      'ServiceRequest.note.text',
    );
    if (note.length) {
      return note[0];
    }

    return '';
  }, [procedimentoEditavel]);

  const edited = React.useMemo(() => !!procedimentoEditavel.reportedBoolean, [
    procedimentoEditavel.reportedBoolean,
  ]);

  const canExpandSubtitle = React.useMemo(
    () => comentario && isSubtitleExpandable && !isOpenForEdit,
    [comentario, isOpenForEdit, isSubtitleExpandable],
  );

  const onEditClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      event.stopPropagation();

      setIsOpenForEdit(true);
    },
    [],
  );

  const toggleCollapse = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      // If disabled, collapse should display the edit form
      if (disabled) {
        setIsOpenForEdit(prev => !prev);
      }

      setIsSubtitleExpanded(prev => !prev);
    },
    [disabled],
  );

  const onEditItem = React.useCallback(async (item: fhir4.DomainResource) => {
    try {
      setloadingConfirmation(true);
      const result = await IntegracaoService.updateFhirResource(
        item as FhirPedidoType,
      );

      if (result) {
        setIsOpenForEdit(false);
        setIsSubtitleExpanded(false);
        setProcedimentoEditavel(result);
      }
    } finally {
      setloadingConfirmation(false);
    }
  }, []);

  const onEditCancel = React.useCallback(async (item: fhir4.DomainResource) => {
    setProcedimentoEditavel(item as FhirPedidoType);
    setIsOpenForEdit(false);
    setIsSubtitleExpanded(false);
  }, []);

  React.useEffect(() => {
    if (subtitleRef.current) {
      setSubtitleExpandable(
        subtitleRef.current.scrollHeight > subtitleRef.current.clientHeight,
      );
    }
  }, []);

  return (
    <Container detailsOpen={isOpenForEdit || isSubtitleExpanded}>
      <Header>
        <HeaderTitle>{nome}</HeaderTitle>

        <Actions>
          <EditTools>
            {edited && <EditedLabel>editado</EditedLabel>}
            {!disabled && (
              <EditButton onClick={onEditClick} disabled={isOpenForEdit} />
            )}
          </EditTools>

          <ExpandButton
            disabled={!(disabled || canExpandSubtitle)}
            collapsed={isSubtitleExpanded}
            onClick={toggleCollapse}
          />
        </Actions>
      </Header>

      <HeaderSubTitle
        ref={subtitleRef}
        highlight={isSubtitleExpanded || isOpenForEdit}
      >
        {comentario}
      </HeaderSubTitle>

      <CollpsableContent ref={editableContentRef} highlight={isOpenForEdit}>
        <FormProcedimento
          resource={procedimentoEditavel as fhir4.ServiceRequest}
          onSubmit={onEditItem}
          onCancel={onEditCancel}
          loading={loadingConfirmation}
          readOnly={disabled}
        />
      </CollpsableContent>
    </Container>
  );
};

export default BoxProcedimentoEditavel;
