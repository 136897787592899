import { ArtifactFormConfig } from '../components/ArtifactForm/ArtifactForm.types';
import { toInternalCodeableConceptValue } from '../components/ArtifactForm/ArtifactForm.business';

const EncaminhamentoFormConfig: ArtifactFormConfig = [
  {
    name: 'code',
    element: 'code',
    label: 'Especialidade',
    type: 'CodeableConcept',
    path: 'category.select(text | coding.display).first()',
    toInternalValue: (resource, value) =>
      toInternalCodeableConceptValue(resource, 'code', value),
  },
  {
    name: 'reasonCode',
    element: 'reasonCode',
    label: 'Indicação clínica',
    type: 'markdown',
    path: 'reasonCode.select(text | coding.display).first()',
    toInternalValue: (resource, value) =>
      toInternalCodeableConceptValue(resource, 'reasonCode', value, true),
  },
  {
    name: 'note',
    element: 'note',
    label: 'Comentários',
    type: 'string',
    path: 'note.text',
    toInternalValue: (resource, value) => ({
      ...resource,
      note: [{ text: value }],
    }),
  },
];

export default EncaminhamentoFormConfig;
