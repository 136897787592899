/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Button from '@/components/Button';

import { useFormContext } from 'react-hook-form';
import ArtifactFieldset from '../ArtifactForm/ArtifactFieldset';
import {
  ArtifactFormConfig,
  FormLoadingState,
} from '../ArtifactForm/ArtifactForm.types';
import { Form, FormActions } from './styles';

export interface ArtifactBaseFormProps
  extends React.FormHTMLAttributes<HTMLFormElement> {
  form: ArtifactFormConfig;
  disabled?: boolean;
  loading?: FormLoadingState;
  readOnly?: boolean;
  showSubmitButton?: boolean;
  showCancelButton?: boolean;
  onCancel?: () => void;
}

const ArtifactBaseForm: React.FC<ArtifactBaseFormProps> = ({
  form,
  disabled = false,
  loading = false,
  readOnly = false,
  showCancelButton = true,
  showSubmitButton = true,
  onCancel,
  ...formProps
}) => {
  const { control, reset } = useFormContext();

  const onSubmitCancel = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      reset();

      if (onCancel) {
        onCancel();
      }
    },
    [onCancel, reset],
  );

  const onSubmit = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
    },
    [],
  );

  const enableActions = React.useMemo(() => {
    return (showCancelButton || showSubmitButton) && !readOnly;
  }, [readOnly, showCancelButton, showSubmitButton]);

  return (
    <Form {...formProps}>
      <ArtifactFieldset
        form={form}
        control={control}
        disabled={disabled}
        loading={!!loading}
        readOnly={readOnly}
      />

      {enableActions && (
        <FormActions>
          {showCancelButton && (
            <Button
              theme="secondary"
              type="button"
              loading={loading === 'cancel'}
              disabled={!!loading || disabled}
              onClick={onSubmitCancel}
            >
              Cancelar
            </Button>
          )}
          {showSubmitButton && (
            <Button
              theme="primary"
              type="submit"
              loading={loading && loading !== 'cancel'}
              disabled={!!loading || disabled}
              onClick={onSubmit}
            >
              Confirmar
            </Button>
          )}
        </FormActions>
      )}
    </Form>
  );
};

export default ArtifactBaseForm;
