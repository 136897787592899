import { toInternalCodeableConceptValue } from '../components/ArtifactForm/ArtifactForm.business';
import { ArtifactFormConfig } from '../components/ArtifactForm/ArtifactForm.types';

const SolicitacaoProcedimentoFormConfig: ArtifactFormConfig = [
  {
    name: 'category',
    element: 'category',
    label: 'Categoria',
    type: 'CodeableConcept',
    path:
      "category.where(coding.code != '71388002').select(text | coding.display).first()",
    toInternalValue: (resource, value) =>
      toInternalCodeableConceptValue(resource, 'category', value, true),
  },
  {
    name: 'orderDetail',
    element: 'orderDetail',
    label: 'Detalhes do pedido',
    type: 'markdown',
    path: 'orderDetail.select(text | coding.display).first()',
    toInternalValue: (resource, value) => ({
      ...resource,
      orderDetail: [{ text: value }],
    }),
  },
  {
    name: 'quantity',
    element: 'quantityQuantity',
    label: 'Quantidade',
    type: 'integer',
    path: 'quantityQuantity.value',
    toInternalValue: (resource, value) => ({
      ...resource,
      quantityQuantity: { value },
    }),
  },
  {
    name: 'reasonCode',
    element: 'reasonCode',
    label: 'Indicação clínica',
    type: 'string',
    path: 'reasonCode.select(text | coding.display).first()',
    toInternalValue: (resource, value) =>
      toInternalCodeableConceptValue(resource, 'reasonCode', value, true),
  },
  {
    name: 'bodySite',
    element: 'bodySite',
    label: 'Local do corpo',
    type: 'CodeableConcept',
    path: 'bodySite.select(text | coding.display).first()',
    toInternalValue: (resource, value) =>
      toInternalCodeableConceptValue(resource, 'bodySite', value, true),
  },
  {
    name: 'note',
    element: 'note',
    label: 'Comentários',
    type: 'string',
    path: 'note.text',
    toInternalValue: (resource, value) => ({
      ...resource,
      note: [{ text: value }],
    }),
  },
  {
    name: 'patientInstruction',
    element: 'patientInstruction',
    label: 'Instruções ao paciente',
    type: 'string',
    path: 'patientInstruction',
    toInternalValue: (resource, value) => ({
      ...resource,
      patientInstruction: value,
    }),
  },
];

export default SolicitacaoProcedimentoFormConfig;
