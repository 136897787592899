import colors from '@/design/colors';
import styled, { css } from 'styled-components';

import { Text } from '@/design/typography';
import shadows from '@/design/shadows';

import FlatList from '@/components/FlatList';

export const BoxList = styled(FlatList)`
  border-radius: 8px;
  border: 1px solid ${colors.purple100};

  padding-top: 8px;

  box-shadow: ${shadows.smallShadow};

  background-color: ${colors.white};
` as typeof FlatList;

export const ResultList = styled.ul`
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 8px;
  }
`;

const ResultItemCSS = css`
  display: flex;
  flex-direction: row;
  align-items: start;
  flex-wrap: wrap;

  text-align: left;

  ${Text(14)};
  color: ${colors.blackAlpha100};

  padding: 8px 16px;
`;

export const LoadingItem = styled.span`
  ${ResultItemCSS};
  opacity: 0.8;

  &:last-child {
    margin-bottom: 8px;
  }
`;

export const EmptyItem = styled.span`
  ${ResultItemCSS};

  &:last-child {
    margin-bottom: 8px;
  }
`;

export const ResultListItem = styled.li`
  display: flex;

  > button {
    ${ResultItemCSS};

    flex: 1;

    :hover:not(:disabled) {
      color: ${colors.purple100};
      background-color: ${colors.purple50};
    }
  }
`;

export const Fade = styled.div`
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 150ms;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms;
  }
`;
