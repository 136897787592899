import React from 'react';
import { Control, Controller } from 'react-hook-form';

import InputField, { TextField } from '@/components/InputField';
import InputFieldDecimal from '@/components/InputFieldDecimal';
import InputFieldInteger from '@/components/InputFieldInteger';

import { ArtifactFieldTypedInfo } from './ArtifactForm.types';

interface Props {
  config: ArtifactFieldTypedInfo;
  control: Control;
  disabled?: boolean;
}

const InputFieldByType = {
  string: InputField,
  markdown: TextField,
  integer: InputFieldInteger,
  decimal: InputFieldDecimal,
  CodeableConcept: InputField,
};

const ArtifactFieldControl: React.FC<Props> = ({
  config,
  control,
  disabled = false,
}) => {
  const ArtifactInputComponent = React.useMemo(() => {
    const { type } = config;
    return InputFieldByType[type];
  }, [config]);

  const label = React.useMemo(() => {
    let title = config.label;

    if (!config.readonly && config.required) {
      title += '*';
    }

    return title;
  }, [config.label, config.readonly, config.required]);

  return (
    <Controller
      name={config.name}
      control={control}
      render={({ field, fieldState }) => (
        <ArtifactInputComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          onChangeText={(value: string) => field.onChange(value)}
          name={config.name}
          title={label}
          autoFocus={config.autoFocus}
          onClick={event => {
            event.stopPropagation();
          }}
          decimalPlaces={2}
          error={fieldState.error?.message}
          rows={1}
          disabled={disabled}
          readOnly={config.readonly}
          placeholder={config.placeholder}
        />
      )}
    />
  );
};

export default ArtifactFieldControl;
