/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { DropdownRef } from '@/components/Dropdown';

import { SearchRef } from '@/components/Search';
import { TipoItemComplementar } from '../../models';
import TipoArtefatoMenu, { TipoArtefatoMenuProps } from '../TipoArtefatoMenu';
import TipoArtefatoLabel from '../TipoArtefatoLabel';

import {
  InputGroup,
  TipoArtefatoDropdown,
  DropdownButton,
  FormControlSearch,
} from './styles';

interface Props
  extends Pick<TipoArtefatoMenuProps, 'options' | 'onTipoSelect'> {
  style?: React.CSSProperties;
  className?: string;

  tipoMenu?: TipoItemComplementar;
  onSearchArtefato?: (type: TipoItemComplementar, term: string) => void;
}

const ArtefatoSearch = React.forwardRef<SearchRef, Props>(
  (
    {
      className,
      style,
      options,
      tipoMenu = 'solicitacao-exame',
      onSearchArtefato,
      onTipoSelect,
    },
    searchRef,
  ) => {
    const dropdownRef = React.useRef<DropdownRef>(null);
    const [tipo, setTipo] = React.useState<TipoItemComplementar>(tipoMenu);

    const handleSearch = React.useCallback(
      (term: string) => {
        if (onSearchArtefato) {
          onSearchArtefato(tipo, term);
        }
      },
      [tipo, onSearchArtefato],
    );

    const handleTipoSelect = React.useCallback(
      (tipoItem: TipoItemComplementar) => {
        setTipo(tipoItem);

        if (dropdownRef.current) {
          dropdownRef.current.hide();
        }

        if (onTipoSelect) {
          onTipoSelect(tipoItem);
        }
      },
      [onTipoSelect],
    );

    React.useEffect(() => {
      setTipo(tipoMenu);
    }, [tipoMenu]);

    return (
      <InputGroup style={style} className={className}>
        <FormControlSearch
          placeholder="Buscar..."
          debounceTimeInMS={300}
          onChange={handleSearch}
          ref={searchRef}
        />

        <TipoArtefatoDropdown
          ref={dropdownRef}
          DropdownToggle={props => <DropdownButton {...props} />}
          DropdownMenu={
            <TipoArtefatoMenu
              onTipoSelect={handleTipoSelect}
              options={options}
            />
          }
        >
          <TipoArtefatoLabel tipo={tipo} />
          <FiChevronDown size={24} />
        </TipoArtefatoDropdown>
      </InputGroup>
    );
  },
);

export default ArtefatoSearch;
