import { FhirArtefatoType } from '@/features/enterprise/models/types';
import BoxExame from '@/features/recursos/exames/components/BoxExame';
import { groupBy } from '@/utils/collections';
import React from 'react';
import CategoriaArtefato from '../../CategoriaArtefato';
import { Content, Wrapper } from './styles';

interface ExamesProps {
  procedimentos: FhirArtefatoType[];
}

const ListaProcedimentosDefault: React.FC<ExamesProps> = ({
  procedimentos,
}) => {
  const procedimentosHasCategorias = React.useMemo(() => {
    return (
      procedimentos.filter(procedimento => !!procedimento.categoria).length > 0
    );
  }, [procedimentos]);

  const CategoriaContent = React.useMemo(() => {
    const groupedArtefatos = groupBy(
      procedimentos,
      option => option.categoria || 'Outros',
    );

    return Object.entries(groupedArtefatos).map(([key, value]) => (
      <CategoriaArtefato
        key={key}
        name={key}
        artefatos={value.map(procedimento => (
          <BoxExame
            key={procedimento.id.toString()}
            exame={procedimento.pedido}
          />
        ))}
      />
    ));
  }, [procedimentos]);
  return (
    <Wrapper>
      <Content>
        {procedimentosHasCategorias ? (
          <>{CategoriaContent}</>
        ) : (
          <>
            {procedimentos.map(procedimento => (
              <BoxExame
                key={procedimento.id.toString()}
                exame={procedimento.pedido}
              />
            ))}
          </>
        )}
      </Content>
    </Wrapper>
  );
};

export default ListaProcedimentosDefault;
